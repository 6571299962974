<template>
    <div class="app-container">
        <div>
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="单位:">
                    <el-select v-model="search.institutionId" size="medium" filterable clearable @change="handleInstitution"
                        placeholder="请选择单位" class="form-line-item"
                               ref="selectInstitution"
                               @visible-change="isShowSelectOptions"
                    >
                        <el-option v-for="(item,index) in institutionList" :key='index' :label="item.institutionName"
                            :value="item.institutionId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="批次:">
                    <el-select v-model="search.batchId" size='medium' clearable placeholder="请选择批次"
                        class="form-line-item" :disabled='batchShow'
                               ref="selectBatchId"
                               @visible-change="isShowSelectOptions"
                    >
                        <el-option v-for="(item,index) in batchList" :key='item.batchId' :label="item.batchName"
                            :value="item.batchId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="评审日期:">
                    <el-date-picker v-model="search.time" size='medium' type="daterange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="打款日期:">
                    <el-date-picker v-model="search.rewardTime" size='medium' type="daterange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="专家:">
                    <el-input placeholder="请输入专家姓名" v-model="search.expertName" size='medium' class="form-line-item">
                    </el-input>
                </el-form-item>
                <!-- <el-form-item label="状态:">
          <el-select v-model="search.statue" size='medium' clearable placeholder="请选择费用状态" class="form-line-item">
            <el-option label="已打款" value="1"></el-option>
            <el-option label="未打款" value="0"></el-option>
          </el-select>
        </el-form-item> -->
                <el-form-item label="题目:">
                    <el-input placeholder="请输入论文题目" style="width:350px;" v-model="search.title" size='medium'
                        class="form-line-item">
                    </el-input>
                </el-form-item>
                <el-form-item style="margin-left:10px;">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="SearchBillHandle">
                        搜索
                    </el-button>
                    <el-button type="primary" size="small" icon="el-icon-refresh" @click="resetHandle">
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </div>

        <!-- 表格 -->
        <div>
            <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;" :refresh="{query: refresh}">
                <template #buttons>
                    <el-button type="success" size="mini" @click="downloadFinancial">下载财务报表</el-button>


                </template>
<!--                <template #tools>-->
<!--                    <el-button type="success" size="mini" @click="uploadCheckResult" style="margin-right:5px">上传审核结果-->
<!--                    </el-button>-->
<!--                    -->
<!--                </template>-->
            </vxe-toolbar>
            <!-- :loading="loading" -->
            <vxe-table :header-cell-style="headerCellStyle" :cell-style='cellStyle' border stripe round
                highlight-hover-row align="center" ref="paperTable" class="mytable-scrollbar unfinancialList" :data="billList" :loading='loading'
                :checkbox-config="{trigger: 'row', highlight: true, range: true}" show-footer
                :footer-method="footerMethod" :merge-footer-items="mergeFooterItems" :export-config="{}"
                :print-config="{}">
                <vxe-column type='seq' title="序号" width="5%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column type='checkbox' title="全选" width="5%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="paperName" title="论文题目" width="30%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="institutionName" title="单位" width="12%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="paperType" title="论文类型" width="8%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="expertName" title="专家姓名" width="8%" :show-overflow="'tooltip'" sortable>
                </vxe-column>
                <vxe-column field="reviewTime" title="评审时间" width="8%" :show-overflow="'tooltip'" sortable>
                </vxe-column>
                <vxe-column field="rewardTime" title="打款时间" width="8%" :show-overflow="'tooltip'" sortable>
                </vxe-column>
                <vxe-column field="reward" title="评审费用" width="8%" :show-overflow="'tooltip'" sortable></vxe-column>
                <vxe-column field="payStatue" title="费用状态" width="8%" :show-overflow="'tooltip'">
                    <template #default='{row}'>
                        {{payStatue[row.payStatue].text}}
                    </template>
                </vxe-column>
                <!-- <vxe-column title="状态">
                    <template #default="{ row }">
                        {{row.reviewNum}}/{{row.totalReviewNum}}
                    </template>
                </vxe-column> -->
                <!-- <vxe-column title="操作">
          <template #default="{ row }">
            <el-button size="mini" type="primary" style="margin-bottom:5px;">打款情况</el-button>
            </el-button>
          </template>
        </vxe-column> -->
            </vxe-table>
            <vxe-pager perfect align='right' :current-page.sync="page.currentPage" :page-size.sync="page.pageSize"
                :total="page.total" :page-sizes="[10, 20, 100, 1000, {label: '全量数据', value: page.total}]"
                :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
                @page-change="handlePageChange">
                <template #left>
                    <vxe-button size="small" @click="firstPage">首页
                    </vxe-button>
                </template>
            </vxe-pager>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'unpay',
        data() {
            return {
                loading: false,
                payStatue: {
                    1: {
                        text: '已支付',
                        color: 'green'
                    },
                    0: {
                        text: '未支付',
                        color: 'red'
                    }
                },
                AlipayBalance: '10000000',
                WeChatBalance: '1000000',
                batchShow: true,
                institutionList: [],
                batchList: [],
                search: {
                    institutionId: '',
                    expertName: '',
                    batchId: '',
                    title: '',
                    statue: '',
                    time: ['', ''],
                    rewardTime:['', ''],
                },
                // 分页
                page: {
                    currentPage: 1,
                    pageSize: 10,
                    pageCount: 40, //自己用
                    pageSizes: [1, 5, 10, 15, 1000],
                    total: 0
                },
                // 账单列表
                billList: [],
                //账单详情对话框
                mergeFooterItems: [{
                    row: 0,
                    col: 0,
                    rowspan: 0,
                    colspan: 0
                }],
            }
        },
        created() {
            window.addEventListener('keydown', this.handleKeyPress);
            this.getInstitution();
            this.getPaperList();
        },
        methods: {
            handleKeyPress(event) {
                if (event.keyCode === 13) {
                    this.SearchBillHandle();
                }
            },

            //表格刷新
            refresh() {
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            //获取送审单位
            //获取送审单位
            getInstitution() {
                let params = {
                    pageIndex: 1,
                    pageSize: 20,
                    isFiled:0
                }
                this.$api.schoolCustom.getSendingInstitution(params)
                    .then(res => {
                        this.institutionList = res.data.data;
                    })
                    .catch(err => {
                        this.$message.warning('接口错误');
                    })
            },
            //获取送审单位的批次
            getBatch(institutionId) {
                let param = new URLSearchParams();
                param.append("institutionId", institutionId);
                // param.append("isFiled",0);
                this.$api.sendingP2p.getBatch(param)
                    .then(res => {
                        console.log(res.data.data);
                        this.batchList = res.data.data;
                    })
                    .catch(err => {
                        this.$message.warning("服务器维护");
                    });
            },
            //机构选择改变
            handleInstitution(val) {
                this.search.batchId = '';
                if (val == '') {
                    this.batchShow = true;
                    return;
                }
                this.batchShow = false;
                this.getBatch(val);
            },
            getPaperList() {
                this.loading = true;
                let param = new URLSearchParams();
                param.append("institutionId", this.search.institutionId);
                param.append("batchId", this.search.batchId);
                param.append("payStatue", 1);
                param.append("title", this.search.title);
                param.append("expertName", this.search.expertName);
                param.append("startTime", this.search.time[0] ? this.dateFormat(this.search.time[0]) : '');
                param.append("endTime", this.search.time[1] ? this.dateFormat(this.search.time[1]) : '');
                param.append("startRewardTime", this.search.rewardTime[0] ? this.dateFormat(this.search.rewardTime[0]) : '');
                param.append("endRewardTime", this.search.rewardTime[1] ? this.dateFormat(this.search.rewardTime[1]) : '');
                param.append("pageIndex", this.page.currentPage);
                param.append("pageSize", this.page.pageSize);
                param.append("isRewardFailed", 0);
                // console.log(this.search.rewardTime)
                // console.log(this.search.time)
                this.$api.financial.getBills(param)
                    .then(res => {
                        console.log(res.data);
                        this.billList = res.data.data;
                        this.page.total = res.data.count;
                        this.loading = false;
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    });
            },
            //搜索打款记录
            SearchBillHandle() {
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            resetHandle() {
                this.search = {
                    institutionId: '',
                    expertName: '',
                    batchId: '',
                    title: '',
                    statue: '',
                    time: ['', ''],
                    rewardTime:['', ''],
                }
            },
            exportHandle() {
                this.$refs.paperTable.openExport()
            },
            //打印
            printHandle() {
                this.$refs.paperTable.openPrint()
            },
            //分页操作
            handlePageChange({
                currentPage,
                pageSize
            }) {
                console.log(currentPage, pageSize);
                this.page.currentPage = currentPage;
                this.page.pageSize = pageSize;
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            //每页多少数据改变
            handleChangePageSize(val) {
                this.page.pageSize = val;
                this.page.currentPage = 1;
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            firstPage() {
                this.page.currentPage = 1;
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            //表尾数据
            footerMethod({
                columns,
                data
            }) {
                const sums = [];
                let total = 0;
                data.forEach(item => {
                    // console.log(item.reward);
                    total += parseFloat(item.reward);
                })
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('总计')
                    } else {
                        switch (column.property) {
                            case 'reward':
                                sums.push(total);
                                break
                            default:
                                sums.push('-');
                        }
                    }
                })
                // 返回一个二维数组的表尾合计
                return [sums]
            },
            //头部样式
            headerCellStyle({
                row,
                column,
                rowIndex,
                columnIndex
            }) {
                return "background-color:#e0e0e0f1"
            },
            //设置单元格样式
            cellStyle({
                row,
                rowIndex,
                column,
                columnIndex
            }) {
                //设置状态颜色
                let styles = {};
                if (column.property == 'payStatue') {
                    styles['color'] = this.payStatue[row.payStatue].color;
                }
                return styles;
            },
            dateFormat: function (time) {
                var datetime = new Date(time);
                // datetime.setTime(time);
                var year = datetime.getFullYear();
                var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() +
                    1;
                var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
                return year + "-" + month + "-" + date;
            },
            // downloadFinancial(){
            //
            // },
            // 导出财务表
            downloadFinancial() {
                console.log(this.$refs.paperTable)
                let seleted = this.$refs.paperTable.getCheckboxRecords();
                console.log(seleted)
                if (seleted.length <= 0) {
                    this.$message.warning("请选择论文!");
                    return;
                }
                let loading = this.$loading({
                    lock: true,
                    text: '下载中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let arr = [];
                seleted.forEach(item => {
                    let id = parseInt(item.reviewId)
                    arr.push(id);
                });
                // let params = new FormData();
                // params.append('reviewIds',arr);
                let params = {
                    reviewIds: arr
                };
                this.$api.financial.downloadFinancial(arr)
                    .then(res => {
                        console.log(res)
                        if (res.data.code == 200) {
                            // 下载第1个
                            this.$api.reviewCheck.getTables({
                                path: res.data.data
                            }).then(res1 => {
                                console.log(res1);
                                const blob = new Blob([res1.data], {
                                    type: 'Application/vnd.ms-excel;charset=utf-8'
                                });
                                let url = window.URL || window.webkitURL;
                                const href = url.createObjectURL(blob);
                                let downloadElement = document.createElement('a');
                                downloadElement.href = href;
                                downloadElement.download = this.getFileName(res.data.data); //下载后文件名
                                document.body.appendChild(downloadElement);
                                downloadElement.click(); //点击下载
                                document.body.removeChild(downloadElement); //下载完成移除元素
                                window.URL.revokeObjectURL(href); //释放掉blob对象
                                loading.close();
                            });
                            loading.close();
                            // let path = res.data.data;
                            // this.getZip(path);
                        }
                    }).catch(err => {
                        console.log(err)
                        this.$message.warning("服务器维护!");
                        loading.close();
                    })
            },
            getFileName(str) {
                return str.substr(str.indexOf('\\') + 1)
            },
            downloadExcel(path){


                this.$api.reviewCheck.getStatisticsTable(reviewIds)
                    .then(res => {
                        this.$api.reviewCheck.getTables({
                            path: res.data.data
                        }).then(res1 => {
                            console.log(res1);
                            const blob = new Blob([res1.data], {
                                type: 'Application/vnd.ms-excel;charset=utf-8'
                            });
                            let url = window.URL || window.webkitURL;
                            const href = url.createObjectURL(blob);
                            let downloadElement = document.createElement('a');
                            downloadElement.href = href;
                            downloadElement.download = this.getFileName(res.data.data); //下载后文件名
                            document.body.appendChild(downloadElement);
                            downloadElement.click(); //点击下载
                            document.body.removeChild(downloadElement); //下载完成移除元素
                            window.URL.revokeObjectURL(href); //释放掉blob对象
                            loading.close();
                        })

                    })
                    .catch(err => {
                        this.$message.warning("导出评审信息表错误");
                    })
            },
            isShowSelectOptions(isShowSelectOptions){
                if(!isShowSelectOptions) this.$refs.selectInstitution.blur();
                if(!isShowSelectOptions) this.$refs.selectBatchId.blur();
            },
        },
        components: {

        }
    }
</script>

<style scoped>
    .form-line-item {
        width: 15em;
    }
    .el-form-item {
        margin-bottom: 10px !important;
    }
</style>

<style >
    .unfinancialList .vxe-table--body-wrapper {
        height: calc(100vh - 390px);
    }
</style>